.page-main > p {
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  color: rgb(0, 0, 0, .87);
  margin-bottom: 24px;
}

.page-main > h1 {
  font-weight: 500px;
  font-size: 40px;
  line-height: 48px;
  color: rgb(0, 0, 0, .87);
}

.page-main > section.licensee, section.access-history {
  margin-top: 56px;
}