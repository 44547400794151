/* Core components */

.ddcapped {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.yellow-button {
  padding: 8px 16px;
  background: #FCF1A1;
  font-size: 14px;
  line-height: 24px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.heading-with-button {
  display: flex;
  align-items: center;
  gap: 24px;
  margin-bottom: 12px;
}

section.overview p {
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 24px;
}

section.overview h1 {
  font-weight: 500;
  font-size: 40px;
  line-height: 48px;
  margin-bottom: 8px
}

section.overview b {
  font-weight: 800;
}

section.catalog-detail {
  margin-top: 36px;
}

section.catalog-detail .metric-overview {
  display: flex;
  gap: 32px;
  margin-bottom: 24px;
}

section.catalog-detail .action-form {
  display: flex;
  gap: 24px;
}

.action-form .input-wrapper {
  display: flex;
}

.action-form .input-wrapper button {
  padding: 12px 16px;
  border: 1px solid #89949F;
  border-left: none;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 40px;
}

.action-form input {
  border: 1px solid #89949F;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  padding: 8px 16px;
  width: 550px;
  outline: none;
  font-size: 16px;
  line-height: 24px;
  height: 40px;
}

.action-form input::placeholder {
  color: #808080;
}

.catalog-detail .catalog-table {
  font-size: 14px;
  line-height: 21px;
  color: #454545;
  width: 100%;
}

.catalog-detail .catalog-table thead {
  box-shadow: 0px 6px 8px 0px #0000001A;
}

.catalog-detail .catalog-table thead th {
  padding: 20px 16px 20px 0px;
  text-align: left;
  border-bottom: 1px solid #CED4DA;
}

.catalog-detail .catalog-table thead th:first-child {
  padding-left: 18px;
}

.catalog-detail .catalog-table tbody td {
  padding: 12px 16px 12px 0px;
}

.catalog-detail .catalog-table tbody tr {
  transition: all 200ms linear;
}

.catalog-detail .catalog-table tbody tr:hover {
  background: #F1F1F1;
}

.catalog-detail .catalog-table tbody td:first-child {
  padding-left: 18px;
}

.catalog-detail .catalog-table tbody td {
  border-bottom: 1px solid #CED4DA;
}

.catalog-detail .catalog-table tbody td:last-child {
  text-align: end;
}

.catalog-detail .catalog-table tbody tr .detail-button {
  opacity: 0;
  transition: all 150ms linear;
  font-size: 14px;
  padding: 4px 12px;
  border-radius: 4px;
  border: 1px solid #000000;
  color: black;
}

.catalog-detail .catalog-table tbody tr:hover .detail-button {
  opacity: 100;
}
