/* Core components */

.yellow-button {
  padding: 8px 16px;
  background: #FCF1A1;
  font-size: 14px;
  line-height: 24px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.heading-with-button {
  display: flex;
  align-items: center;
  gap: 24px;
  margin-bottom: 12px;
}

section.overview p {
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 24px;
}

section.overview h1 {
  font-weight: 500;
  font-size: 40px;
  line-height: 48px;
  margin-bottom: 8px;
  color: #000000DE;
}

section.overview b {
  font-weight: 800;
}

section.overview .detail {
  display: flex;
  gap: 36px;
  margin-bottom: 10px;
  color: #304050;
}

/* Data Summary */
section.data-summary {
  margin-top: 56px;
}

section.data-summary h2 {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #212529;
}

section.data-summary .data-summary-container {
  display: flex;
  gap: 72px;
  margin-top: 24px;
  color: #304050;
}

.data-summary-container li {
  font-size: 16px;
  line-height: 24px;
}

.data-summary-container li b {
  font-weight: 800;
}

.data-summary-container .data-summary-chart {
  display: flex;
  gap: 24px;
}

.data-summary-chart li {
  line-height: 24px;
  color: #304050;
}

/* Licensees */
section.licensee {
  margin-top: 56px;
}

section.licensee h2, section.training-history h2 {
  font-weight: 500;
  font-size: 24px;
  font-weight: 28px;
  color: #212529;
}

/* training history inference section */

section.training-history {
  margin-top: 56px;
}

section.training-history button.play-inference {
  padding: 4px 12px;
  border-radius: 4px;
  border: 1px solid black;
  background: transparent;
}

section.training-history .data-table tbody tr td:last-child {
  opacity: 0;
  transition: all 0.15s linear;
}


section.training-history .data-table tbody tr:hover td:last-child {
  opacity: 1;
}
