
.album-cover {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  border-radius: 4px;
}

.track-info {
  flex-grow: 1;
}

.track-info h1 {
  margin: 0;
  font-size: 1.5em;
}

.track-info p {
  margin: 0;
  color: gray;
}

.close-button {
  background: none;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
}

.player {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.tabs {
  display: flex;
}

.tab-active {
  border: 1px solid #DEE2E6;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom: none;
  background-color: #EAECEF40;
}

.tab-inactive {
  border-bottom: 1px solid #DEE2E6;
  color: #245B9E;
}

.tab-content {
  display: none;
}

.tab-content.active {
  display: block;
}
.tab-content table {
  width: 100%;
  border-collapse: collapse;
}

.tab-content th {
  border-bottom: 1px solid #ddd;
  font-weight: 700;
}

.tab-content th,
.tab-content td {
  text-align: left;
  padding: 8px;
  font-size: 11px;
  color: #304050;
}

.tab-content tr:last-child td {
  border-bottom: 1px solid #ddd;
}

.tab-content td a {
  color: #2D73C8;
}

.tab-content td:first-child {
  border-left: 1px solid #ddd;
}

.tab-content td:last-child {
  border-right: 1px solid #ddd;
}

.tab-content td:last-child {
  max-width: 210px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tab-content a {
  color: #007bff;
  text-decoration: none;
}

.tab-content a:hover {
  text-decoration: underline;
}

.metadata-table {
  width: 100%;
  border-collapse: collapse;
}

.metadata-header {
  text-align: left;
  padding: 8px;
  font-weight: bold;
  border: 1px solid #ddd;
}

.metadata-data {
  padding: 8px;
  border: 1px solid #ddd;
}

.success-notification {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 10px 20px;
    z-index: 1000;
  }
  
  .success-notification-content {
    display: flex;
    align-items: center;
  }
  
  .success-icon {
    color: green;
    margin-right: 10px;
    font-size: 20px;
  }
  
  .success-message {
    font-size: 16px;
    color: #333;
  }
  
  .success-close-button {
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    margin-left: 20px;
  }


.expiration-modal-content {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 400px;
  padding: 20px;
  position: relative;
}

.expiration-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.expiration-modal-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.expiration-icon {
  color: red;
  margin-right: 10px;
}

.expiration-modal-header h2 {
  margin: 0;
  color: red;
  font-size: 16px;
  font-weight: 400;
}

.expiration-modal-body {
  margin-bottom: 20px;
}

.expiration-modal-footer {
  display: flex;
  justify-content: flex-end;
}

.expiration-cancel-button {
  background-color: #e0e0e0;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  margin-right: 10px;
  cursor: pointer;
}

.expiration-deactivate-button {
  background-color: #d9534f;
  border: none;
  border-radius: 5px;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
}