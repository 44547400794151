@tailwind base;
@tailwind components;
@tailwind utilities;


/* Overridding CSS */
body {
  margin: 0px;
  font-family: "Mulish", sans-serif;
  font-optical-sizing: auto;
}

button {
  border: none;
  cursor: pointer;
  background-color: transparent;
}

h1,
h2,
p {
  margin: 0px;
}

ul {
  list-style-type: none;
  padding: 0px;
  margin: 0px;
}

.app {
  width: 100%;
  display: flex;
}


.app main {
  width: 100%;
}


.navbar {
  max-width: 240px;
  width: 100%;
  padding: 12px;
}

.navbar ul {
  margin: 24px 0 0 0;
  color: rgba(0, 0, 0, .87);
}

.navbar ul li {
  cursor: pointer;
  padding: 10px 12px;
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 2px;
}

.navbar ul li.active {
  background-color: #E7F1FF;
  border-radius: 4px;
}

.navbar ul li:hover {
  background-color: #F1F1F1;
  border-radius: 4px;
}

main header {
  height: fit-content;
  padding: 14px 20px 14px 0px;
  display: flex;
  justify-content: end;
  align-items: center;
  position: sticky;
  top: 0px;
  background: white;
}

main>header>img:nth-child(2) {
  border-radius: 8px;
}

main header .auth-user {
  cursor: pointer;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  gap: 4px;
  color: #304050;
}

div.page-main {
  padding: 24px 0px 24px 0px;
  margin: 0px 24px 0px 12px;
  overflow-x: hidden;
  box-sizing: border-box;
}

.yellow-button {
  padding: 8px 16px;
  background: #FCF1A1;
  font-size: 14px;
  line-height: 24px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.heading-with-button {
  display: flex;
  align-items: center;
  gap: 24px;
  margin-bottom: 12px;
}

.data-table {
  text-align: left;
  color: #454545;
  font-size: 14px;
  line-height: 21px;
  border: none;
  border-collapse: collapse;
}

.data-table thead th {
  padding: 12px 24px 12px 18px;
  font-weight: 700;
}

.data-table tbody td {
  padding: 6px 24px 6px 18px
}

.data-table tbody tr {
  transition: all 0.15s linear;
}

.data-table tbody tr:hover {
  cursor: pointer;
  background: #F1F1F1;
}

.data-table tbody td:first-child {
  padding-left: 18px;
}

div[data-amplify-container] {
  width: 340px !important;
}

div[data-amplify-router] {
  border-radius: 20px !important;
  box-shadow: 0px 0px 10px 0px #0000001A !important;
}

.amplify-tabs__list {
  display: none !important;
}

#signIn-panel {
  padding: 0px !important;
  border-radius: 20px !important;
}

#signIn-panel form {
  padding: 20px !important;
}

#signIn-panel form .amplify-button[type='submit'] {
  width: fit-content !important;
  padding: 8px 16px !important;
  font-size: 14px !important;
  background: #2D73C8 !important;
  margin-left: auto !important;
}

#signIn-panel div[data-amplify-footer] {
  display: none !important;
}

#signIn-panel form div.amplify-field-group__outer-end {
  display: none !important;
}

#signIn-panel form input[type='password'] {
  border-radius: 4px !important;
}

.somms-tooltip-left:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-width: 8px;
  border-style: solid;
  border-color: transparent #212529 transparent transparent;
  top: 50%;
  transform: translateY(-50%);
  left: -16px;
}

[data-amplify-router] {
  border: none !important;
}

[data-amplify-authenticator][data-variation=modal] {
  background-image: url("../assets/background.jpg");
  background-position: 0%;
  background-size: cover;
}

.dashboard-background {
  background-image: url("../assets/background.jpg");
}

/* Override NextUI config */

html {
  --nextui-radius-small: 4px !important;
}