.dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-toggle {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .dropdown-menu {
    /* display: block;
    position: absolute;
    top: 100%;
    left: 10%; */
    background: white;
    border: 1px solid #ccc;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    width: 320px;
  }
  
  .dropdown-menu ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  
  .dropdown-menu ul li button {
    padding: 10px 20px;
    text-decoration: none;
    color: #333;
  }
  
  .dropdown-menu ul li:hover {
    background: #f0f0f0;
  }
